<template>
    <div class="awardContainer">
        <div class="eventContainer">
            <div :class="{'eventItem':true,'choose':currentEvent===item,'unchoose':currentEvent!==item}" v-for="item in events" :key="item" @click="chooseEvent(item)">
                <img class="eventImg" :src="require('../../assets/'+handleEventName(item)+'.png')" v-if="currentEvent!==item"/>
                
                <img class="eventImg" src="../../assets/chooseEvent.png" v-else/>
                <div class="txt">{{handleEventName(item)}}</div>
            </div>
        </div>
  
        <div class="rankList">
            <div class="outContainer">
                <div class="scoreItem gray">My Award</div>
                <div class="scoreItem">
                    <div class="container" style="font-size:1.4vh">
                        <div style="width:16%">Subject</div>
                        <div style="width:16%">Total Score</div>
                        <!-- <div style="width:20%">排名</div> -->
                        <div style="width:16%">Individual Award</div>
                        <div style="width:16%">School Team Award</div>
                        <div style="width:16%">Completion Certificate</div>
                        <div style="width:16%">Global Final</div>
                    </div>
                </div>
                <div class="scoreItem gray">
                    <div class="container">
                        <div style="width:16%">{{handleEventName(myAward.event)}}</div>
                        <div style="width:16%">{{myAward.score.total_score}}</div>
                        <div style="width:16%;">
                            <el-link v-if="myAward.personal_award" @click="show=true" type="primary">{{myAward.personal_award.real_award_name}}</el-link>
                            <div v-else>None</div>
                        </div>
                        <div style="width:16%">
                            <el-link v-if="myAward.group_award" @click="showGroup=true" type="primary">View</el-link>
                            <div v-else>None</div>
                        </div>
                        <div style="width:16%" >
                            <el-link v-if="myAward.completion" type="primary" @click="showCom=true">View</el-link>
                            <div v-else>None</div>
                        </div>
                        <div style="width:16%">
                            <div v-if="!myAward.status">
                                <span v-if="myAward.rank<=5">Qualified</span>
                                <span v-else-if="myAward.rank<=10">Alternate</span>
                                <span v-else>Unqualified</span>
                            </div>
                            <div v-if="myAward.status">
                                <span>{{myAward.status==='yes'?'Qualified':'Alternate'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="outContainer">
                <div class="scoreItem gray">Outstanding School Team</div>
                <div v-for="(item,index) in groupAwards" :key="item._id.$id" :class="{'scoreItem':true,'gray':index%2!==0}">
                    <div class="container">
                        <div style="width:100%;">{{item.account_name}}</div>
                        <!-- <div style="width:50%">{{Math.floor(item.score)+'分'}}</div> -->
                    </div>
                </div>
            </div>
            <div class="outContainer">
                <div class="scoreItem gray">National Top 10 Student</div>
                <div class="scoreItem">
                    <div class="container" style="font-size:1.4vh">
                        <div style="width:40%;overflow:hidden;">School</div>
                        <div style="width:20%;overflow:hidden;">Student</div>
                        <div style="width:20%;overflow:hidden;">Total Score</div>
                        <div style="width:20%;overflow:hidden;">Rank</div>
                        <!-- <div style="width:30%;overflow:hidden;">奖项</div> -->
                    </div>
                </div>
                <div v-for="(item,index) in personalAwards" :key="item._id.$id" :class="{'scoreItem':true,'gray':index%2===0}">
                    <div class="container">
                        <div style="width:40%;overflow:hidden;">{{item.account_name}}</div>
                        <div style="width:20%;overflow:hidden;">{{item.student_name}}</div>
                        <div style="width:20%;overflow:hidden;">{{item.total_score}}</div>
                        <div style="width:20%;overflow:hidden;">{{item.real_rank}}</div>
                        <!-- <div style="width:30%;overflow:hidden;">{{item.real_award_name}}</div> -->
                    </div>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="show">
            <!-- <el-button @click="printPersonal" type="primary">打印</el-button> -->
            <div class="cer-container" id="cerP" v-if="myAward.personal_award">
                <img src="../../assets/cer.jpg" class="model" oncontextmenu="return false;"/>
                <div class="student">{{upfirstName(myAward.personal_award.student.student_givenName_pinyin)+' '+upfirstName(myAward.personal_award.student.student_lastName_pinyin)}}</div>
                <div class="account">{{myAward.personal_award.account?myAward.personal_award.account.account_nameEN:''}}</div>
                <div class="item">
                    <div class="event">{{myAward.personal_award.application_event}}</div>
                    <div class="award"><span v-if="myAward.personal_award.real_rank<=10">National {{myAward.personal_award.real_rank}}{{rank(myAward.personal_award.real_rank)}} Place and </span>{{myAward.personal_award.real_award_name}}</div>
                </div>
                <div class="intro">in the Academic Marathon China Conference and National Academic Challenge for the year of 2020-2021. We appreciate your faith in academic learning and your excellent performance. </div>
                <div class="time">Aug 11th, 2021</div>
                <div class="timespan">Date</div>
                <div class="notePic">
                    <img src="../../assets/note.png" class="notePicItem"/>
                </div>
                <div class="noteTxt">
                    <div>Gunter Koo</div>
                    <div>Managing Director</div>
                    <div>of Academic Marathon</div>
                </div>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="showGroup">
            <div class="group-container" v-if="myAward.group_award">
                <img src="../../assets/cer.jpg" class="model" oncontextmenu="return false;"/>
                <div class="student">{{upfirstName(myAward.group_award.student.student_givenName_pinyin)+' '+upfirstName(myAward.group_award.student.student_lastName_pinyin)}}</div>
                <div class="account">{{myAward.group_award.account?myAward.group_award.account.account_nameEN:''}}</div>
                <div class="item">
                    <div class="event">{{myAward.group_award.event}}</div>
                    <div class="award">Outstanding School Team</div>
                </div>
                <div class="intro">in the Academic Marathon China Conference and National Academic Challenge for the year of 2020-2021. We appreciate your faith in academic learning and your excellent performance. </div>
                <div class="time">Aug 11th, 2021</div>
                <div class="timespan">Date</div>
                 <div class="notePic">
                    <img src="../../assets/note.png" style="height:50px;"/>
                </div>
                <div class="noteTxt">
                    <div>Gunter Koo</div>
                    <div>Managing Director</div>
                    <div>of Academic Marathon</div>
                </div>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="showCom">
            <div class="com-container" v-if="myAward.completion">
                <img src="../../assets/cer.jpg" class="model" oncontextmenu="return false;"/>
                <div class="student">{{upfirstName(myAward.completion.student.student_givenName_pinyin)+' '+upfirstName(myAward.completion.student.student_lastName_pinyin)}}</div>
                <div class="account">{{myAward.completion.account?myAward.completion.account.account_nameEN:''}}</div>
                <div>
                    <div class="completionIntro">Has successfully completed 12-hour intense college-level studies and examinations with strong academic rigor In the subject of</div>
                    <div class="completionEvent">{{myAward.completion.application_event}}</div>
                </div>
                <div class="intro">in the Academic Marathon China Conference and National Academic Challenge for the year of 2020-2021. We appreciate your faith in academic learning and your excellent performance. </div>
                <div class="time">Aug 11th, 2021</div>
                <div class="timespan">Date</div>
                <div class="notePic">
                    <img src="../../assets/note.png" class="notePicItem"/>
                </div>
                <div class="noteTxt">
                    <div>Gunter Koo</div>
                    <div>Managing Director</div>
                    <div>of Academic Marathon</div>
                </div>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import {getGroupAwards,getAwards,getMyAward} from '../../api/index'
import {getUserId} from '../../utils/store'
export default {
    data(){
        return{
            events:[
                'Artithon - Academic Marathon in Art',
                'Biothon - Academic Marathon in Biology',
                'Chemithon - Academic Marathon in Chemistry',
                'Econthon - Academic Marathon in Economics',
                'Geothon - Academic Marathon in Geography',
                'Historithon - Academic Marathon in History',
                'Mathethon - Academic Marathon in Mathematics',
                'Physithon - Academic Marathon in Physics',
                'Psychothon - Academic Marathon in Psychology'
            ],
            currentEvent:'Artithon - Academic Marathon in Art',
            groupAwards:[],
            personalAwards:[],
            myAward:{
            },
            show:false,
            showGroup:false,
            showCom:false
        }
    },
    mounted(){
        this.getGroupAwards()
        this.getAwards()
        this.getMyAward()
    },
    methods:{
        handleEventName(event){
            let event_array = event.split(" ")
            return event_array[0]
        },
        getAwards(){
            getAwards('20-21AM_Final',this.currentEvent).then(res=>{
                console.log(res)
                if(res.data.code===0){
                    this.personalAwards = res.data.data
                }
            })
        },
        getMyAward(){
            getMyAward(getUserId()).then(res=>{
                console.log(res)
                if(res.data.code===0){
                    this.myAward = res.data.data
                }
            })
        },
        getGroupAwards(){
            getGroupAwards('20-21AM_Final',this.currentEvent).then(res=>{
                if(res.data.code===0){
                    this.groupAwards = res.data.data
                }
            })
        },
        chooseEvent(item){
            this.currentEvent = item
            this.getGroupAwards()
            this.getAwards()
        },
        upfirstName(name){
            let transferName = name.toLowerCase();
            return transferName.charAt(0).toUpperCase() + transferName.slice(1)
        },
        rank(number){
            if(number===1){
                return 'st';
            }else if(number===2){
                return 'nd';
            }else if(number===3){
                return 'rd';
            }else{
                return 'th'
            }
        },
        printPersonal(){
            let url = this.$router.resolve({
                path:'/blank'
            })
            let newWindow=window.open(url.href,"_blank");//打印窗口要换成页面的url
            let obj = document.getElementById('cerP')

            let docStr = obj.innerHTML;
            
            newWindow.document.write(docStr);
            
            newWindow.document.close();
            
            newWindow.print();
            
            newWindow.close();
            
        }
    }
}
</script>

<style lang="less">
.el-dialog{
    width:320mm;
}
.awardContainer{
 
    .eventContainer{
        display: flex;
        flex-wrap: nowrap;
        width: 94%;
        .eventItem{
            cursor: pointer;
            width: 10%;
            height: 6vh;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-left:10px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            text-align: center;
            line-height: 2vh;
            .eventImg{
                width: 100%;
                height: 6vh;
            }
            .txt{
                position: absolute;
            }
        }
        .choose{
            color: white;
        }
        .unchoose{
            color: #072D76;
        }
    }
    .rankList{
        width: 94%;
        margin-top:1vh;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #072D76;
        .outContainer{
            border: 4px solid #072D76;
            background: white;
            margin-top:2vh;
            .scoreItem{
                height: 4.2vh;
                line-height: 4.2vh;
                text-align: center;
                font-size: 1.6vh;
                .container{
                    width: 100%;
                    margin: 0 auto;
                    display: flex;
                    font-size: 1.5vh;
                    justify-content: center;
                    align-items: center;
                    height: 4vh;
                    line-height: 4vh;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }
        .personalScore{
            background: white;
            border: 4px solid #072D76;
            padding-bottom: 1.2vh;
            margin-top:1vh;
            .txt{
                text-align: center;
                font-size: 2vh;
                height: 4vh;
                line-height: 4vh;
            }
            .indiContainer{
                display: flex;
                justify-content: space-around;
                align-items: center;
                text-align: center;
                .item{
                    width: 25%;
                }
                
            }
        }
    }
    .gray{
        background: rgb(230,234,241);
    }

}
.cer-container{
    position: relative;
    width: 297mm;
    height: 208.7mm;
    border: 2px solid #DCDFE6;
    .model{
        width: 297mm;
        height: 208.7mm;
    }
    .student{
        width: 297mm;
        position: absolute;
        top: 270px;
        font-size: 38px;
        font-weight: 800;
        text-align: center;
    }
    .account{
        width: 297mm;
        position: absolute;
        top: 336px;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        
    }
    .item{
        .event{
            width: 297mm;
            position: absolute;
            top: 376px;
            font-size: 28px;
            font-weight: 800;
            text-align: center;
        }
        .award{
            width: 297mm;
            position: absolute;
            top: 424px;
            font-size: 30px;
            font-weight: 800;
            text-align: center;
        }
    }
    
    .intro{
        width: 230mm;
        position: absolute;
        left: 34mm;
        top: 480px;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        word-wrap:normal;
        word-break:normal

    }
    .time{
        width: 180px;
        border-bottom: 1px solid black;
        position: absolute;
        line-height: 36px;
        font-weight: 500;
        left: 320px;
        font-size: 18px;
        text-align: center;
        top: 560px;
    }
    .timespan{
        width: 180px;
        position: absolute;
        left: 320px;
        top: 602px;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
    }
    .completionIntro{
        width: 256mm;
        position: absolute;
        left: 21mm;
        top: 376px;
        font-weight:550;
        line-height: 24px;
        font-size: 18px;
        text-align: center;
        word-wrap:normal;
        word-break:normal
    }
    .completionEvent{
        width: 297mm;
        position: absolute;
        top: 426px;
        font-size: 30px;
        font-weight: 800;
        text-align: center;
    }
    .notePic{
        position: absolute;
        top: 544px;
        font-size: 30px;
        left: 640px;
        border-bottom: 1px solid black;
        height: 50px;
        width: 160px;
        text-align: center;
    }
    .notePicItem{
        position: absolute;
        height: 60px;
        top: 8px;
        left: 10px;

    }
    .noteTxt{
        width: 180px;
        text-align: center;
        position: absolute;
        top: 606px;
        font-size: 16px;
        left: 640px;
        line-height: 18px;
        word-wrap:normal;
        word-break:normal
    }
}
.group-container{
    position: relative;
    width: 297mm;
    height: 208.7mm;
    border: 2px solid #DCDFE6;
    .model{
        width: 297mm;
        height: 208.7mm;
    }
    .student{
        width: 297mm;
        position: absolute;
        top: 270px;
        font-size: 38px;
        font-weight: 800;
        text-align: center;
    }
    .account{
        width: 297mm;
        position: absolute;
        top: 336px;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        
    }
    .item{
        .event{
            width: 297mm;
            position: absolute;
            top: 376px;
            font-size: 28px;
            font-weight: 800;
            text-align: center;
        }
        .award{
            width: 297mm;
            position: absolute;
            top: 424px;
            font-size: 30px;
            font-weight: 800;
            text-align: center;
        }
    }
    
    .intro{
        width: 230mm;
        position: absolute;
        left: 34mm;
        top: 480px;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        word-wrap:normal;
        word-break:normal

    }
    .time{
        width: 180px;
        border-bottom: 1px solid black;
        position: absolute;
        line-height: 36px;
        font-weight: 500;
        left: 320px;
        font-size: 18px;
        text-align: center;
        top: 560px;
    }
    .timespan{
        width: 180px;
        position: absolute;
        left: 320px;
        top: 602px;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
    }
    .completionIntro{
        width: 256mm;
        position: absolute;
        left: 21mm;
        top: 376px;
        font-weight:550;
        line-height: 24px;
        font-size: 18px;
        text-align: center;

    }
    .completionEvent{
        width: 297mm;
        position: absolute;
        top: 426px;
        font-size: 30px;
        font-weight: 800;
        text-align: center;
    }
    .notePic{
        position: absolute;
        top: 544px;
        font-size: 30px;
        left: 640px;
        border-bottom: 1px solid black;
        height: 50px;
        width: 160px;
        text-align: center;
    }
    .notePicItem{
        position: absolute;
        height: 60px;
        top: 8px;
        left: 10px;

    }
    .noteTxt{
        width: 180px;
        text-align: center;
        position: absolute;
        top: 606px;
        font-size: 16px;
        left: 640px;
        line-height: 18px;
        word-wrap:normal;
        word-break:normal
    }
}
.com-container{
    position: relative;
    width: 297mm;
    height: 208.7mm;
    border: 2px solid #DCDFE6;
    .model{
        width: 297mm;
        height: 208.7mm;
    }
    .student{
        width: 297mm;
        position: absolute;
        top: 270px;
        font-size: 38px;
        font-weight: 800;
        text-align: center;
    }
    .account{
        width: 297mm;
        position: absolute;
        top: 336px;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        
    }
    .item{
        .event{
            width: 297mm;
            position: absolute;
            top: 376px;
            font-size: 28px;
            font-weight: 800;
            text-align: center;
        }
        .award{
            width: 297mm;
            position: absolute;
            top: 424px;
            font-size: 30px;
            font-weight: 800;
            text-align: center;
        }
    }
    
    .intro{
        width: 230mm;
        position: absolute;
        left: 34mm;
        top: 480px;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        word-wrap:normal;
        word-break:normal

    }
    .time{
        width: 180px;
        border-bottom: 1px solid black;
        position: absolute;
        line-height: 36px;
        font-weight: 500;
        left: 320px;
        font-size: 18px;
        text-align: center;
        top: 560px;
    }
    .timespan{
        width: 180px;
        position: absolute;
        left: 320px;
        top: 602px;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
    }
    .completionIntro{
        width: 256mm;
        position: absolute;
        left: 21mm;
        top: 376px;
        font-weight:550;
        line-height: 24px;
        font-size: 18px;
        text-align: center;
        word-wrap:normal;
        word-break:normal
    }
    .completionEvent{
        width: 297mm;
        position: absolute;
        top: 426px;
        font-size: 30px;
        font-weight: 800;
        text-align: center;
    }
    .notePic{
        position: absolute;
        top: 544px;
        font-size: 30px;
        left: 640px;
        border-bottom: 1px solid black;
        height: 50px;
        width: 160px;
        text-align: center;
    }
    .notePicItem{
        position: absolute;
        height: 60px;
        top: 8px;
        left: 10px;

    }
    .noteTxt{
        width: 180px;
        text-align: center;
        position: absolute;
        top: 606px;
        font-size: 16px;
        left: 640px;
        line-height: 18px;
        word-wrap:normal;
        word-break:normal
    }
}
</style>